/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
/* LEVEL 2 */
/* LEVEL 3 */
/******* layout-large.less 2013-1-16 *******/
.desk {
  width: 95%;
  max-width: 1140px;
}
.main-nav {
  float: right;
  margin-top: 75px;
}
.mood {
  padding: 0;
  height: 544px;
}
.cb-layout2 .mood {
  height: 344px;
  padding: 0;
}
.cb-layout1 h1 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.375;
}
.cb-index-all a {
  width: 19px;
  height: 19px;
}
div.south div.unit {
  margin: 0 15px;
  width: 360px;
}
.carousel-shadow {
  width: 360px;
}
#carousel {
  width: 1140px;
}
#carousel--inner {
  width: 1170px;
  margin-left: -15px;
}
#view div.south div.head {
  bottom: 24px;
}
.hr {
  margin-top: 50px;
  margin-bottom: 50px;
}
.popover-toggle {
  width: 75px;
  height: 75px;
  margin-left: -37px;
  top: 60%;
}
.popover-close {
  top: -30px;
  right: -30px;
  width: 60px;
  height: 60px;
}
.footer {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.33333333;
}
.footer-nav {
  float: right;
  margin-right: -1.31578947%;
  width: 85.52631579%;
}
.krengerglas {
  width: 14.47368421%;
  margin-top: 44px;
}
.navi .cb-toggle {
  display: none;
}
.main-nav > .navi > .item {
  position: relative;
  z-index: 3;
}
.main-nav > .navi > .item:hover {
  z-index: 4;
}
.main-nav .menu {
  font-size: 18px;
  font-size: 1.8rem;
  border: 1px solid #fff;
  margin-right: -1px;
  padding: 0.75em 0.83em;
}
.main-nav > .navi > .item:hover > .menu {
  border-color: #dadada;
}
.main-nav > .navi > .item > .menu:hover {
  color: #024485;
}
.main-nav > .navi > .path > .path {
  color: #024485;
  font-weight: normal;
  font-family: 'RobotoBold';
}
.main-nav .item > .navi {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  position: absolute;
  left: 0;
  right: -1px;
  width: auto;
  top: 100%;
  border: 1px solid #dadada;
  background: #fff;
  border-top: 0;
  z-index: 4;
  min-width: 10em;
}
.main-nav .item:hover > .navi,
.cbe--edit-navigation .path > .navi {
  display: block;
}
.main-nav .item:hover > .navi:after {
  position: absolute;
  top: -1px;
  left: 50%;
  border-top: 1px solid #fff;
  width: 21px;
  height: 10px;
  margin-left: -10px;
  content: '';
  background: url(/images/main-nav__item.png) no-repeat;
}
.main-nav .item > .navi .menu {
  font-size: 15px;
  font-size: 1.5rem;
  border: 0;
  padding: 0.21em 5px 0.21em 13px;
}
.main-nav .item > .navi > .init > .menu {
  padding-top: 1.1em;
}
.main-nav .item > .navi > .exit > .menu {
  padding-bottom: 1em;
}
.footer-nav .menu {
  font-size: 15px;
  font-size: 1.5rem;
}
.footer-nav > .navi > .item {
  width: 16.92307692%;
  margin: 10px 1.53846154%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 1.28205128%;
  margin-left: 1.28205128%;
  width: 97.43589744%;
}
.area h2,
.area .foot {
  margin-right: 3.50877193%;
  margin-left: 3.50877193%;
}
.area .part,
.area > .grid table {
  margin-right: 3.50877193%;
  margin-left: 3.50877193%;
  width: 92.98245614%;
}
.area > .slim {
  width: 47.43589744%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 7.20720721%;
  margin-left: 7.20720721%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 85.58558559%;
}
.area > .slim .tiny {
  width: 85.58558559%;
}
.main {
  width: 100%;
}
.main > .unit {
  margin-right: 1.28205128%;
  margin-left: 1.28205128%;
  width: 97.43589744%;
}
.main h2,
.main .foot {
  margin-right: 3.50877193%;
  margin-left: 3.50877193%;
}
.main .part,
.main > .grid table {
  margin-right: 3.50877193%;
  margin-left: 3.50877193%;
  width: 92.98245614%;
}
.main > .slim {
  width: 30.76923077%;
}
.main > .slim h2,
.main > .slim .foot,
.main > .slim .part,
.main > .slim.grid table {
  margin-right: 11.11111111%;
  margin-left: 11.11111111%;
}
.main > .slim .part,
.main > .slim.grid table {
  width: 77.77777778%;
}
.main > .slim .tiny {
  width: 77.77777778%;
}
.cb-layout2 .base {
  width: 100%;
}
.cb-layout2 .base > .unit {
  margin-right: 1.28205128%;
  margin-left: 1.28205128%;
  width: 97.43589744%;
}
.cb-layout2 .base h2,
.cb-layout2 .base .foot {
  margin-right: 3.50877193%;
  margin-left: 3.50877193%;
}
.cb-layout2 .base .part,
.cb-layout2 .base > .grid table {
  margin-right: 3.50877193%;
  margin-left: 3.50877193%;
  width: 92.98245614%;
}
.cb-layout2 .base .tiny {
  width: 26.31578947%;
}
.cb-layout2 .base > .slim {
  width: 30.76923077%;
}
.cb-layout2 .base > .slim h2,
.cb-layout2 .base > .slim .foot,
.cb-layout2 .base > .slim .part,
.cb-layout2 .base > .slim.grid table {
  margin-right: 11.11111111%;
  margin-left: 11.11111111%;
}
.cb-layout2 .base > .slim .part,
.cb-layout2 .base > .slim.grid table {
  width: 77.77777778%;
}
.cb-layout2 .base > .slim .tiny {
  width: 77.77777778%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 1.28205128%;
  margin-left: 1.28205128%;
  width: 97.43589744%;
}
.south h2,
.south .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.south .part,
.south > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south > .slim {
  width: 30.76923077%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 100%;
}
.south > .slim .tiny {
  width: 100%;
}
.main .wide {
  width: 64.1025641%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.area,
.cb-layout2 .base {
  margin-left: -1.31578947%;
  width: 102.63157895%;
}
.main {
  margin-top: 60px;
}
.cb-layout2 .main {
  margin-top: 20px;
}
.unit {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.38888889;
}
.cb-layout2 h1,
.unit h2,
.unit h3 {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.25;
}
.seam {
  padding-top: 40px;
  padding-bottom: 40px;
}
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */